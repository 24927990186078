exports.components = {
  "component---src-layouts-default-tsx": () => import("./../../../src/layouts/default.tsx" /* webpackChunkName: "component---src-layouts-default-tsx" */),
  "component---src-layouts-faq-tsx": () => import("./../../../src/layouts/faq.tsx" /* webpackChunkName: "component---src-layouts-faq-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preset-tsx": () => import("./../../../src/pages/preset.tsx" /* webpackChunkName: "component---src-pages-preset-tsx" */),
  "component---src-pages-redirect-tsx": () => import("./../../../src/pages/redirect.tsx" /* webpackChunkName: "component---src-pages-redirect-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */)
}

